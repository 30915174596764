@import "~styles/config";

.configuration {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 15px;

    h1 {
      font-size: 14px;
      text-transform: uppercase;
      padding: 20px;
      text-align: center;
      margin: 0;
      border-bottom: 1px solid currentColor;
    }

    legend {
      margin: 0 0 20px;
      max-width: 100%;
    }

    .content {
      box-sizing: border-box;
      background-color: $color-white;
      width: 100%;
      border-radius: 4px;
      border: 1px solid $color-border;
      margin-top: 30px;
      box-sizing: border-box;
      max-width: 340px;
      width: 100%;

        .fields {

            padding: 20px;
            @media screen and (max-width: $mobile-width) {

                .beginTestsButton {
                    width: 100%;
                }
            }

            > div {
                margin-top: 20px;
            }
        }
    }
}

.buttonContainer {
  text-align: right;
}
