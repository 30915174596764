@import url(https://fonts.googleapis.com/css?family=Roboto:300,500,700);
@font-face{font-family:'Museo Sans Rounded';font-weight:500;src:url(/static/media/exljbris_-_museosansrounded-500-webfont.a1ae81a9.eot);src:url(/static/media/exljbris_-_museosansrounded-500-webfont.a1ae81a9.eot) format("embedded-opentype"),url(/static/media/exljbris_-_museosansrounded-500-webfont.201cd6e6.woff2) format("woff2"),url(/static/media/exljbris_-_museosansrounded-500-webfont.df5f1de5.woff) format("woff"),url(/static/media/exljbris_-_museosansrounded-500-webfont.0eb05775.svg) format("svg")}@font-face{font-family:'Museo Sans Rounded';font-weight:300;src:url(/static/media/2E49F4_0_0.219562e2.eot);src:url(/static/media/2E49F4_0_0.219562e2.eot) format("embedded-opentype"),url(/static/media/2E49F4_0_0.35eb4db0.woff2) format("woff2"),url(/static/media/2E49F4_0_0.66995c59.woff) format("woff"),url(/static/media/2E49F4_0_0.db7e907d.ttf) format("truetype")}@font-face{font-family:'Museo Sans Rounded';font-weight:100;src:url(/static/media/2E49F4_1_0.5ea8fd8a.eot);src:url(/static/media/2E49F4_1_0.5ea8fd8a.eot) format("embedded-opentype"),url(/static/media/2E49F4_1_0.59d65d0e.woff2) format("woff2"),url(/static/media/2E49F4_1_0.8e0e1e6a.woff) format("woff"),url(/static/media/2E49F4_1_0.9f0bd46a.ttf) format("truetype")}@font-face{font-family:'Museo Sans Rounded';font-weight:700;src:url(/static/media/2E49F4_2_0.c3eb14e8.eot);src:url(/static/media/2E49F4_2_0.c3eb14e8.eot) format("embedded-opentype"),url(/static/media/2E49F4_2_0.ee5b5937.woff2) format("woff2"),url(/static/media/2E49F4_2_0.5d898622.woff) format("woff"),url("/museo/2E49F4_2_0.ttf") format("truetype")}

body{margin:0;padding:0;font-family:"Roboto";font-weight:300;color:#274856;background-color:#F3F6F9;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-size:16px}html,body,#root{height:100%}

.App_loading__3Rsj5{position:absolute;top:50%;text-align:center;width:100vw}

.Configuration_configuration__2daI_{display:flex;flex-direction:row;flex-wrap:wrap;justify-content:center;padding:0 15px}.Configuration_configuration__2daI_ h1{font-size:14px;text-transform:uppercase;padding:20px;text-align:center;margin:0;border-bottom:1px solid currentColor}.Configuration_configuration__2daI_ legend{margin:0 0 20px;max-width:100%}.Configuration_configuration__2daI_ .Configuration_content__2g0EA{box-sizing:border-box;background-color:#fff;width:100%;border-radius:4px;border:1px solid #D0DEE5;margin-top:30px;box-sizing:border-box;max-width:340px;width:100%}.Configuration_configuration__2daI_ .Configuration_content__2g0EA .Configuration_fields__1lzg5{padding:20px}@media screen and (max-width: 760px){.Configuration_configuration__2daI_ .Configuration_content__2g0EA .Configuration_fields__1lzg5 .Configuration_beginTestsButton__2mPsV{width:100%}}.Configuration_configuration__2daI_ .Configuration_content__2g0EA .Configuration_fields__1lzg5>div{margin-top:20px}.Configuration_buttonContainer__1wRhl{text-align:right}

.Button_button__2Z3WX{border:1px solid #D4007A;background-color:#D4007A;color:#fff;padding:7px 19px;cursor:pointer;border-radius:17px;font-family:"Museo Sans Rounded";font-weight:700;font-size:15px;height:34px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;transition:all .15s ease-in}.Button_button__2Z3WX:focus{outline:none}.Button_button__2Z3WX:disabled{background-color:#9D9D9D;border-color:#9D9D9D;cursor:default}.Button_green__2dsiZ{border-color:#7DB200;background-color:#7DB200}.Button_green__2dsiZ:hover,.Button_green__2dsiZ:focus{background-color:#689300}.Button_secondary__I_EAy{background-color:transparent;color:#274856;border-color:#274856}.Button_secondary__I_EAy:hover,.Button_secondary__I_EAy:focus{background-color:#274856;color:#fff}

.Radio_vooRadio__uHw6W,.Radio_vooCheckbox__3ndTr{position:absolute;opacity:0;box-sizing:border-box}.Radio_vooRadio__uHw6W+label,.Radio_vooCheckbox__3ndTr+label{display:flex;padding:10px 0;transform:none;transform-origin:left center;transition:all .3s ease-in;cursor:pointer;box-sizing:border-box}.Radio_vooRadio__uHw6W+label:before,.Radio_vooCheckbox__3ndTr+label:before{content:'';box-sizing:border-box;display:block;height:22px;width:22px;border:2px solid currentColor;border-radius:4px;transition:border .15s ease-in;margin:-2px 10px 0 0}.Radio_vooRadio__uHw6W:checked+label:before,.Radio_vooCheckbox__3ndTr:checked+label:before{border-width:8px}.Radio_vooRadio__uHw6W:disabled+label,.Radio_vooCheckbox__3ndTr:disabled+label{color:#9D9D9D;cursor:auto}.Radio_vooRadio__uHw6W:disabled+label:before,.Radio_vooCheckbox__3ndTr:disabled+label:before{border-color:#9D9D9D}@media screen and (min-width: 761px){.Radio_vooRadio__uHw6W+label,.Radio_vooCheckbox__3ndTr+label{padding:5px 0}}.Radio_vooRadio__uHw6W+label:before{border-radius:50%}


.Info_info__2cieL{background-color:rgba(212,0,122,0.1);padding:10px;border-radius:4px;font-size:14px;line-height:1.5}.Info_info__2cieL .Info_container__2Zsuc{display:flex;flex-direction:row}.Info_info__2cieL .Info_container__2Zsuc .Info_icon__Ub7OQ{height:20px;width:20px;min-width:20px;background:url(/static/media/infos_round.1bdc3e6d.svg) center no-repeat}.Info_info__2cieL .Info_container__2Zsuc .Info_content__2aFsV{color:#D4007A;font-size:14px;padding:0 10px}.Info_infoBlue__3duM_{background-color:#E4ECF0;margin-top:20px}.Info_infoBlue__3duM_ .Info_container__2Zsuc .Info_icon__Ub7OQ{background:url(/static/media/infos_round-blue.b3c2be28.svg) center no-repeat}.Info_infoBlue__3duM_ .Info_container__2Zsuc .Info_content__2aFsV{color:#274856}

.Speedtest_container__1Tf0E{margin:0 auto;max-width:780px;padding:0 10px;width:100%;display:flex;flex-direction:column;justify-content:space-between;box-sizing:border-box}@media (min-width: 400px){.Speedtest_container__1Tf0E{padding:0 20px}}h1{margin:30px 0 20px;font-family:"Museo Sans Rounded";font-size:22px}

.TestsContainer_content__2SuFz{flex:1 1;margin-bottom:10px}@media screen and (min-width: 761px){.TestsContainer_content__2SuFz{margin-bottom:0}}.TestsContainer_row__2qyV4{box-sizing:border-box;display:flex;margin-right:-10px;margin-left:-10px;flex-wrap:wrap}.TestsContainer_colMd6__3keUg{box-sizing:border-box;flex:0 0 100%;max-width:100%;padding-right:10px;padding-left:10px}.TestsContainer_colMd6__3keUg:first-child{display:flex}@media screen and (min-width: 761px){.TestsContainer_colMd6__3keUg{flex:0 0 50%;max-width:50%}}.TestsContainer_endTestsContainer__YIcxy{margin:20px 0 0;display:flex;justify-content:space-between}.TestsContainer_mlAuto___Ney9{margin-left:auto}

.TestRoomWidget_testroomwidget__upgXE{border-style:solid}.TestRoomWidget_widget__1ipF0 .TestRoomWidget_container__3F5_y{display:flex;flex-direction:row;justify-content:space-between;padding:20px 15px;box-sizing:border-box;width:100%;flex-wrap:wrap}.TestRoomWidget_widget__1ipF0 .TestRoomWidget_container__3F5_y .TestRoomWidget_buttons__TaVGk{display:flex;flex-direction:row;flex:0.3 1;justify-content:flex-end}.TestRoomWidget_widget__1ipF0 .TestRoomWidget_container__3F5_y .TestRoomWidget_buttons__TaVGk .TestRoomWidget_testButton__3L54t{margin-left:10px}.TestRoomWidget_widget__1ipF0 .TestRoomWidget_container__3F5_y .TestRoomWidget_iconText__2BELL{flex:0.25 1}.TestRoomWidget_widget__1ipF0 .TestRoomWidget_container__3F5_y .TestRoomWidget_title__1cwmq{margin:0 0 5px;font-family:"Museo Sans Rounded";font-weight:700;font-size:16px;flex:0.2 1;text-align:left}.TestRoomWidget_badge__2mw6f{padding:3px 10px;border:1px solid currentColor;color:#7DB200;font-size:12px;font-weight:500;line-height:1;border-radius:4px;text-transform:uppercase;display:inline-block}.TestRoomWidget_gaugeData__334JN{width:100%;margin-top:10px;display:flex;justify-content:space-between;font-family:"Museo Sans Rounded";font-size:16px;font-weight:700}

.Widget_widget__3qqp0{border-width:1px;border-color:#D0DEE5;background-color:#fff;width:100%;box-sizing:border-box;border-radius:4px;border-style:solid;margin-bottom:10px}

.Gauge_number__3iCLP{display:flex}.Gauge_number__3iCLP .Gauge_arrow__bzRRq{margin-right:5px}.Gauge_number__3iCLP .Gauge_arrowVertical__1AoYL{margin-top:-2px;margin-right:5px}

.AddRoomWidget_addroomwidget__2zTRf{display:flex;flex-direction:column;justify-content:center;border:1px solid #D0DEE5;background-color:#fff;cursor:pointer;padding:30px 0;margin-bottom:20px;box-shadow:none;transition:box-shadow .15s ease-in}@media screen and (min-width: 761px){.AddRoomWidget_addroomwidget__2zTRf{margin-bottom:0}}.AddRoomWidget_addroomwidget__2zTRf:hover,.AddRoomWidget_addroomwidget__2zTRf:focus{box-shadow:0 38px 15px -30px rgba(208,222,229,0.7)}.AddRoomWidget_addroomwidget__2zTRf .AddRoomWidget_container__2t_UK{display:flex;flex-direction:row;justify-content:center;align-items:center}.AddRoomWidget_addroomwidget__2zTRf .AddRoomWidget_container__2t_UK .AddRoomWidget_icon__26YcY{background:url(/static/media/plus_round.e0d0034d.svg) center no-repeat;height:20px;width:20px;min-width:20px}.AddRoomWidget_addroomwidget__2zTRf .AddRoomWidget_container__2t_UK .AddRoomWidget_content__3Y0M7{font-weight:500;margin-left:10px;font-family:"Museo Sans Rounded"}

.EndTestsModal_question__RLrR9{margin-top:20px}.EndTestsModal_buttons__3uANy{display:flex;flex-direction:column;flex-wrap:wrap;margin-top:20px;width:100%}.EndTestsModal_buttons__3uANy .EndTestsModal_endTestsButton__2FPFX{margin-top:15px}.EndTestsModal_error__3-UiG{color:#D54E2F;text-align:center;margin-top:10px}.EndTestsModal_roomsContainer__1VFrk .EndTestsModal_roomsGroup__1rvjU{margin-top:20px}.EndTestsModal_roomsContainer__1VFrk .EndTestsModal_roomsGroup__1rvjU .EndTestsModal_roomTitle__3KYS5{font-weight:700}.EndTestsModal_roomsContainer__1VFrk .EndTestsModal_roomsGroup__1rvjU .EndTestsModal_rooms__2ttoI{margin:0;margin-top:10px;padding-left:5px;list-style-type:none}.EndTestsModal_roomsContainer__1VFrk .EndTestsModal_roomsGroup__1rvjU .EndTestsModal_rooms__2ttoI>li{text-indent:-5px}.EndTestsModal_roomsContainer__1VFrk .EndTestsModal_roomsGroup__1rvjU .EndTestsModal_rooms__2ttoI>li:before{content:"- ";text-indent:-5px}

.Modal_title__LJri9{text-align:center;padding:20px;border-bottom:1px solid #274856;position:relative}.Modal_title__LJri9 h2{font-family:"Museo Sans Rounded";font-weight:700;font-size:14px;margin:0}.Modal_content__2wnL-{display:flex;flex-direction:column;justify-content:space-between;padding:20px;padding-top:0}.Modal_close__1vADI{border:none;box-shadow:none;background:transparent;color:#274856;-webkit-appearance:none;-moz-appearance:none;width:58px;height:100%;position:absolute;right:0;top:0;cursor:pointer}.Modal_close__1vADI:hover,.Modal_close__1vADI:focus{outline:medium none}.Modal_close__1vADI svg{width:20px;height:20px}.Modal_close__1vADI path{fill:currentColor}

.EmptyModal_overlay__1LiFy{position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.4)}.EmptyModal_container__2j5kG{position:absolute;top:50%;left:50%;right:auto;bottom:auto;background:#fff;overflow:auto;--webkit-overflow-scrolling: touch;outline:none;margin-right:-50%;transform:translate(-50%, calc(-50% - .5px));box-sizing:border-box;border-radius:4px}@media all and (-ms-high-contrast: none), (-ms-high-contrast: active){.EmptyModal_container__2j5kG{transform:translate(-50%, -50%)}}@media screen and (max-width: 760px){.EmptyModal_container__2j5kG{transform:none;top:0;left:0;width:100%}}.EmptyModal_container__2j5kG .EmptyModal_modal__3WVNw{max-width:340px;width:340px;box-sizing:border-box}@media screen and (max-width: 760px){.EmptyModal_container__2j5kG .EmptyModal_modal__3WVNw{width:100%;max-width:none}}

.RoomModal_modal__2FjRU .RoomModal_content__1c5HO .RoomModal_buttonsContainer__2ESeV{display:flex;flex-direction:row;justify-content:space-between}.RoomModal_modal__2FjRU .RoomModal_content__1c5HO>div{margin-top:20px}legend{margin:20px 0;max-width:100%}

.Input_container__p8-sG{text-align:left}.Input_container__p8-sG .Input_info__tAyRy{margin-top:10px}.Input_container__p8-sG .Input_input__pre-O{border:1px solid #D0DEE5;height:40px;font-size:13pt;padding:0 15px;box-sizing:border-box;width:100%;border-radius:4px;font-family:"Roboto";color:#274856;font-weight:500}.Input_container__p8-sG .Input_input__pre-O::-webkit-input-placeholder{color:#D0DEE5}.Input_container__p8-sG .Input_input__pre-O:-ms-input-placeholder{color:#D0DEE5}.Input_container__p8-sG .Input_input__pre-O::-ms-input-placeholder{color:#D0DEE5}.Input_container__p8-sG .Input_input__pre-O::placeholder{color:#D0DEE5}.Input_container__p8-sG .Input_input__pre-O:-ms-input-placeholder{color:#D0DEE5}@media screen and (max-width: 760px){.Input_container__p8-sG .Input_input__pre-O{height:50px}}.Input_container__p8-sG .Input_withTitle__2xe5x{margin-top:10px}.Input_container__p8-sG .Input_error__3m3Vh{border-color:#D54E2F}.Input_container__p8-sG .Input_error-message__2X_KA{color:#D54E2F;font-size:13px;margin-top:5px;margin-left:2px;font-family:"Roboto";font-weight:100}

.Dropdown_dropdown__2RCiT .Dropdown_withTitle__28mJ_{margin-top:20px}.Dropdown_dropdown__2RCiT .Dropdown_content__1cmcd{border:1px solid #D0DEE5;border-radius:4px;position:relative;overflow:hidden;height:40px;box-sizing:border-box}.Dropdown_dropdown__2RCiT .Dropdown_content__1cmcd:after{background:url(/static/media/chevron.e1b46d6e.svg) center no-repeat;background-size:20px 12px;height:12px;width:20px;display:inline-block;position:absolute;right:7px;top:15px;content:' ';fill:#D4007A;transform:rotate(90deg);pointer-events:none;padding-right:5px}@media all and (-ms-high-contrast: none), (-ms-high-contrast: active){.Dropdown_dropdown__2RCiT .Dropdown_content__1cmcd:after{background-size:inherit}}@media screen and (max-width: 760px){.Dropdown_dropdown__2RCiT .Dropdown_content__1cmcd:after{top:20px}}@-moz-document url-prefix(){.Dropdown_dropdown__2RCiT .Dropdown_content__1cmcd:after{background-size:unset}}@media screen and (max-width: 760px){.Dropdown_dropdown__2RCiT .Dropdown_content__1cmcd{height:50px}}.Dropdown_dropdown__2RCiT .Dropdown_content__1cmcd select{border:none;height:100%;width:110%;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;font-family:"Roboto";font-size:16px;color:#274856;padding-left:15px;background-color:#fff}

.SpeedtestModal_speedtestmodal__Cl6X9{text-align:center}.SpeedtestModal_speedtestmodal__Cl6X9 .SpeedtestModal_gauge__ktG5b{justify-content:center;margin:15px}@media all and (-ms-high-contrast: none){.SpeedtestModal_speedtestmodal__Cl6X9{overflow:hidden}}.SpeedtestModal_iframe__16Wrc{overflow:visible;-ms-overflow-style:none;width:calc(100% + 20px);margin:0 0 -10px -10px;height:380px}@media all and (-ms-high-contrast: none){.SpeedtestModal_iframe__16Wrc{height:390px;margin:0 0 -5px -10px}}h3{font-family:"Museo Sans Rounded";font-weight:700;font-size:20px;text-transform:uppercase;margin-bottom:0}.SpeedtestModal_mb20__29z8S{margin-bottom:20px}.SpeedtestModal_badge__2YViv{padding:3px 10px;border:1px solid currentColor;color:#7DB200;font-size:12px;font-weight:500;line-height:1;border-radius:4px;text-transform:uppercase;margin-top:5px;display:inline-block}.SpeedtestModal_gaugeData__2h91W{width:100%;margin:20px 0;display:flex;justify-content:space-between;font-family:"Museo Sans Rounded";font-size:16px;font-weight:700}.SpeedtestModal_loaderContainer__3dr07{position:relative}.SpeedtestModal_loader__3ZF_U{width:170px;height:170px;position:absolute;top:15px;transform:translateX(-50%);margin-left:50%;border:2px solid rgba(212,0,122,0.2);border-radius:50%;-webkit-animation:SpeedtestModal_pulse__3Ehx_ 2s infinite;animation:SpeedtestModal_pulse__3Ehx_ 2s infinite}@-webkit-keyframes SpeedtestModal_pulse__3Ehx_{0%{transform:translateX(-50%) scale(0.95)}70%{transform:translateX(-50%) scale(1)}100%{transform:translateX(-50%) scale(0.95)}}@keyframes SpeedtestModal_pulse__3Ehx_{0%{transform:translateX(-50%) scale(0.95)}70%{transform:translateX(-50%) scale(1)}100%{transform:translateX(-50%) scale(0.95)}}.SpeedtestModal_bottomBtn__NxFbK{margin:10px auto 0}[hidden]{display:none}

@media screen and (max-width: 760px){.TestsEndedModal_modal__3lPYD{margin-top:20px}}.TestsEndedModal_testsendedmodal__1ysVg{background-color:rgba(125,178,0,0.1);padding:20px 15px;color:#7DB200;display:flex}.TestsEndedModal_testsendedmodal__1ysVg svg{height:20px;width:20px;margin-right:10px}.TestsEndedModal_testsendedmodal__1ysVg svg path{fill:currentColor}.TestsEndedModal_testsendedmodal__1ysVg h3{font-weight:700;font-family:"Museo Sans Rounded";font-size:16px;text-transform:none;margin:0 0 10px}.TestsEndedModal_loaderContainer__35sgg{padding:20px 0 0;display:flex;justify-content:center;margin-left:-30px}.TestsEndedModal_loaderDot__2Ii9C{transition:transform 5000ms ease;box-sizing:border-box;border:3px solid #D4007A;height:12px;width:12px;margin:0 3px 0 0;display:block;border-radius:50%;animation:TestsEndedModal_wave__316-b 2s ease-out infinite;-webkit-animation:TestsEndedModal_wave__316-b 2s ease-out infinite;transform-origin:30% 10%}.TestsEndedModal_loaderDot__2Ii9C:nth-child(1){animation-delay:200ms;-webkit-animation-delay:200ms}.TestsEndedModal_loaderDot__2Ii9C:nth-child(2){animation-delay:100ms;-webkit-animation-delay:100ms}.TestsEndedModal_loaderDot__2Ii9C:nth-child(3){animation-delay:0;-webkit-animation-delay:0}@-webkit-keyframes TestsEndedModal_wave__316-b{0%{transform:rotate(0)}40%,100%{transform:rotate(360deg)}}@keyframes TestsEndedModal_wave__316-b{0%{transform:rotate(0)}40%,100%{transform:rotate(360deg)}}

.TestInfos_dataLevels__1Ua0-{display:flex;justify-content:space-between;font-size:12px;font-family:"Museo Sans Rounded";margin-bottom:20px;line-height:1.2}.TestInfos_dataLevels__1Ua0- h3{font-size:12px;font-weight:500;text-transform:none;margin:0 0 15px;line-height:1.2}.TestInfos_dataLevels__1Ua0- .TestInfos_number__bPrX2{font-size:20px;font-weight:700;display:block}.TestInfos_dataLevels__1Ua0->div:nth-child(5n+1) .TestInfos_number__bPrX2{color:#D54E2F}.TestInfos_dataLevels__1Ua0->div:nth-child(5n+2) .TestInfos_number__bPrX2{color:#D54E2F}.TestInfos_dataLevels__1Ua0->div:nth-child(5n+3) .TestInfos_number__bPrX2{color:#F19300}.TestInfos_dataLevels__1Ua0->div:nth-child(5n+4) .TestInfos_number__bPrX2{color:#7DB200}.TestInfos_dataLevels__1Ua0->div:nth-child(5n+5) .TestInfos_number__bPrX2{color:#7DB200}

