@import "~styles/config";

.container {
  margin: 0 auto;
  max-width: 780px;
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  @media(min-width: 400px) {
    padding: 0 20px;
  }
}

h1 {
  margin: 30px 0 20px;
  font-family: $font-family-secondary;
  font-size: 22px;
}