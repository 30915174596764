@import "~styles/config";

.dropdown {
    .withTitle {
        margin-top: 20px;
    }

    .content {
        border: 1px solid $color-border;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        height: 40px;
        box-sizing: border-box;

        &:after {
            background: url(./images/chevron.svg) center no-repeat;
            background-size: 20px 12px;
            height: 12px;
            width: 20px;
            display: inline-block;
            position: absolute;
            right: 7px;
            top: 15px;
            content: ' ';
            fill: $color-pink;
            transform: rotate(90deg);
            pointer-events: none;
            padding-right: 5px;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background-size: inherit;
            }

            @media screen and (max-width: $mobile-width) {
                top: 20px;
            }

            @-moz-document url-prefix() {
                background-size: unset;
            }
        }

        @media screen and (max-width: $mobile-width) {
            height: 50px;
        }

        select {
            border: none;
            height: 100%;
            width: 110%;
            outline: none;
            appearance: none;
            font-family: $font-family;
            font-size: 16px;
            color: $color-font;
            padding-left: 15px;
            background-color: $color-white;
        }
    }
}
