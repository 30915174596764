@import "~styles/config";
.number {
  display: flex; 
  .arrow {
    margin-right: 5px;
  }
  .arrowVertical {
    margin-top: -2px;
    margin-right: 5px;
}
}