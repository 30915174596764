@import "~styles/config";

.container {
    text-align: left;

    .info {
        margin-top: 10px;
    }

    .input {
        border: 1px solid $color-border;
        height: 40px;
        font-size: 13pt;
        padding: 0 15px;
        box-sizing: border-box;
        width: 100%;
        border-radius: 4px;
        font-family: $font-family;
        color: $color-font;
        font-weight: $font-medium;

        &::placeholder {
            color: $color-border;
        }

        &:-ms-input-placeholder {
            color: $color-border;
        }

        @media screen and (max-width: $mobile-width) {
            height: 50px;
        }
    }

    .withTitle {
        margin-top: 10px;
    }

    .error {
        border-color: $color-red;
    }

    .error-message {
        color: $color-red;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 2px;
        font-family: $font-family;
        font-weight: $font-light;
    }
}
