@import "~styles/config";

.modal {
    @media screen and (max-width: $mobile-width) {
        margin-top: 20px;
    }
}

.testsendedmodal {
    background-color: rgba(125, 178, 0, 0.1);
    padding: 20px 15px;
    color: $color-green;
    display: flex;

    svg {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        path {
          fill: currentColor
        }
    }

    h3 {
        font-weight: $font-bold;
        font-family: $font-family-secondary;
        font-size: 16px;
        text-transform: none;
        margin: 0 0 10px;
    }
}

.loaderContainer {
  padding: 20px 0 0;
  display: flex;
  justify-content: center;
  margin-left: -30px;
}

.loaderDot {
  -webkit-transition: transform 5000ms ease;
  transition: transform 5000ms ease;
  box-sizing: border-box;
  border: 3px solid $color-pink;
  height: 12px;
  width: 12px;
  margin: 0 3px 0 0;
  display: block;
  border-radius: 50%;
  animation: wave 2s ease-out infinite;
  -webkit-animation: wave 2s ease-out infinite;
  transform-origin: 30% 10%;
}

.loaderDot:nth-child(1) {
  animation-delay: 200ms;
  -webkit-animation-delay: 200ms;
}

.loaderDot:nth-child(2) {
  animation-delay: 100ms;
  -webkit-animation-delay: 100ms;
}

.loaderDot:nth-child(3) {
  animation-delay: 0;
  -webkit-animation-delay: 0;
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  40%,
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes wave {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  40%,
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}