@import "~styles/config";

.addroomwidget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid $color-border;
    background-color: $color-white;
    cursor: pointer;
    padding: 30px 0;
    margin-bottom: 20px;
    box-shadow: none;
    transition: box-shadow .15s ease-in;
    @media screen and (min-width: $desk-width) {
      margin-bottom: 0;
    }

    &:hover, &:focus {
      box-shadow: 0 38px 15px -30px rgba(208, 222, 229, 0.7);
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .icon {
            background: url(./images/plus_round.svg) center no-repeat;
            height: 20px;
            width: 20px;
            min-width: 20px;
        }

        .content {
            font-weight: $font-medium;
            margin-left: 10px;
            font-family: $font-family-secondary;
        }
    }
}
