@import url("https://fonts.googleapis.com/css?family=Roboto:300,500,700");

@import "~styles/config";

body {
    margin: 0;
    padding: 0;
    font-family: $font-family;
    font-weight: $font-regular;
    color: $color-font;
    background-color: $color-background;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

html, body, #root {
    height: 100%;
}
