// Fonts
$font-family: 'Roboto';
$font-family-secondary: 'Museo Sans Rounded';
$font-light: 100;
$font-regular: 300;
$font-medium: 500;
$font-bold: 700;
$font-size: 16px;

// Colors
$color-pink: #D4007A;
$color-green: #7DB200;
$color-white: #FFFFFF;
$color-font: #274856;
$color-background: #F3F6F9;
$color-border: #D0DEE5;
$color-red: #D54E2F;
$color-orange: #F19300;
$color-disabled: #9D9D9D;

// screen sizes
$mobile-width: 760px;
$desk-width: 761px;