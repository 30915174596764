@import "~styles/config";

.info {
    background-color: rgba(212, 0, 122, 0.1);
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    

    .container {
        display: flex;
        flex-direction: row;

        .icon {
            height: 20px;
            width: 20px;
            min-width: 20px;
            background: url(./images/infos_round.svg) center no-repeat;
        }

        .content {
            color: $color-pink;
            font-size: 14px;
            padding: 0 10px;
        }
    }
}
.infoBlue {
  background-color: #E4ECF0;
  margin-top: 20px;
  .container {
    .icon {
      background: url(./images/infos_round-blue.svg) center no-repeat;
    }
    .content {
      color: $color-font;
    }
  }
}