@import "~styles/config";

.dataLevels {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-family: $font-family-secondary;
  margin-bottom: 20px;
  line-height: 1.2;

  h3 {
    font-size: 12px;
    font-weight: 500;
    text-transform: none;
    margin: 0 0 15px;
    line-height: 1.2;
  }

  .number {
    font-size: 20px;
    font-weight: 700;
    display: block;
  }
  $colors: $color-red, $color-red, $color-orange, $color-green, $color-green;
  
  @for $i from 1 through length($colors) {
      > div:nth-child(#{length($colors)}n+#{$i}) .number{
          color: nth($colors, $i)
      }
  }
}
