@import "~styles/config";

.content {
  flex: 1;
  margin-bottom: 10px;
  @media screen and (min-width: $desk-width) {
    margin-bottom: 0;
  }
}

.row {
  box-sizing: border-box;
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
  flex-wrap: wrap;
}

.colMd6 {
  box-sizing: border-box;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  &:first-child {
    display: flex;
  }

  @media screen and (min-width: $desk-width) {
    flex: 0 0 50%;
    max-width: 50%
  }
}

.endTestsContainer {
    margin: 20px 0 0;
    display: flex;
    justify-content: space-between;
}

.mlAuto {margin-left: auto;}