@import "~styles/config";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    background: #fff;
    overflow: auto;
    --webkit-overflow-scrolling: touch;
    outline: none;
    margin-right: -50%;
    transform: translate(-50%, calc(-50% - .5px));
    box-sizing: border-box;
    border-radius: 4px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        transform: translate(-50%, -50%);
    }

    @media screen and (max-width: $mobile-width) {
        transform: none;
        top: 0;
        left: 0;
        width: 100%;
    }

    .modal {
        max-width: 340px;
        width: 340px;
        box-sizing: border-box;

        @media screen and (max-width: $mobile-width) {
          width: 100%;
          max-width: none;
      }
    }
}
