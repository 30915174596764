@import "~styles/config";

.question {
    margin-top: 20px;
}

.buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 20px;
    width: 100%;

    .endTestsButton {
        margin-top: 15px;
    }
}

.error {
    color: $color-red;
    text-align: center;
    margin-top: 10px;
}

.roomsContainer {
    .roomsGroup {
        margin-top: 20px;

        .roomTitle {
            font-weight: $font-bold;
        }

        .rooms {
            margin: 0;
            margin-top: 10px;
            padding-left: 5px;
            list-style-type: none;
        }

        .rooms > li {
            text-indent: -5px;
        }

        .rooms > li:before {
            content: "- ";
            text-indent: -5px;
        }
    }
}
