@import "~styles/config.scss";

.button {
    border: 1px solid $color-pink;
    background-color: $color-pink;
    color: $color-white;
    padding: 7px 19px;
    cursor: pointer;
    border-radius: 17px;
    font-family: $font-family-secondary;
    font-weight: $font-bold;
    font-size: 15px;
    height: 34px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .15s ease-in;

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: $color-disabled;
        border-color: $color-disabled;
        cursor: default;
    }
}

.green {
  border-color: $color-green;
  background-color: $color-green;
  &:hover, &:focus {
    background-color: darken($color-green, 6%);
  }
}

.secondary {
    background-color: transparent;
    color: $color-font;
    border-color: $color-font;
    &:hover, &:focus {
      background-color: $color-font;
      color: $color-white
    }
}
