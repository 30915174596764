@import "~styles/config";

.widget {
    border-width: 1px;
    border-color: $color-border;
    background-color: $color-white;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    border-style: solid;
    margin-bottom: 10px;
}
