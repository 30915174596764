@import "~styles/config";

.testroomwidget {
  border-style: solid;
}

.widget {
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 15px;
    box-sizing: border-box;
    width: 100%;
    flex-wrap: wrap;

    .buttons {
      display: flex;
      flex-direction: row;
      flex: 0.3;
      justify-content: flex-end;

      .testButton {
        margin-left: 10px;
      }
    }

    .iconText {
      flex: 0.25;
    }

    .title {
      margin: 0 0 5px;
      font-family: $font-family-secondary;
      font-weight: $font-bold;
      font-size: 16px;
      flex: 0.2;
      text-align: left;
    }
  }
}

.badge {
  padding: 3px 10px;
  border: 1px solid currentColor;
  color: $color-green;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  text-transform: uppercase;
  display: inline-block;
}

.gaugeData {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-family: $font-family-secondary;
  font-size: 16px;
  font-weight: 700;
}