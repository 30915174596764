@import "~styles/config";

.title {
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid $color-font;
    position: relative;

    h2 {
        font-family: $font-family-secondary;
        font-weight: $font-bold;
        font-size: 14px;
        margin: 0;
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    padding-top: 0;
}

.close {
  border: none;
  box-shadow: none;
  background: transparent;
  color: $color-font;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 58px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;

  &:hover, &:focus {
    outline: medium none;
  }

  svg {
    width: 20px;
    height: 20px;
  }
  
  path {
    fill: currentColor
  }
}
