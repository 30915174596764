@import "~styles/config";
.speedtestmodal {
    text-align: center;

    .gauge {
        justify-content: center;
        margin: 15px;
    }
    @media all and (-ms-high-contrast: none) {
      overflow: hidden;
    }
}

.iframe {
  overflow: visible;
  -ms-overflow-style: none;
  width: calc(100% + 20px);
  margin: 0 0 -10px -10px; // for shadows -_-
  height: 380px;
  @media all and (-ms-high-contrast: none) {
    height: 390px;
    margin: 0 0 -5px -10px; // for shadows -_-
  }
}

h3 {
  font-family: $font-family-secondary;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.mb20 {
  margin-bottom: 20px;
}

.badge {
  padding: 3px 10px;
  border: 1px solid currentColor;
  color: $color-green;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  text-transform: uppercase;
  margin-top: 5px;
  display: inline-block;
}

.gaugeData {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  font-family: $font-family-secondary;
  font-size: 16px;
  font-weight: 700;
}

.loaderContainer {
  position: relative;
}

.loader {
  width: 170px;
  height: 170px;
  position: absolute;
  top:15px;    
  transform: translateX(-50%);
  margin-left: 50%;
  border: 2px solid rgba($color-pink, .2);
  border-radius: 50%;
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: translateX(-50%) scale(0.95);
	}

	70% {
		transform: translateX(-50%) scale(1);
	}

	100% {
		transform: translateX(-50%) scale(0.95);
	}
}

.bottomBtn {
  margin: 10px auto 0;
}

[hidden] {display: none;}