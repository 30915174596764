@font-face {
  font-family: 'Museo Sans Rounded';
  font-weight: 500;
  src: url('../assets/fonts/museo/exljbris_-_museosansrounded-500-webfont.eot');
  src: url('../assets/fonts/museo/exljbris_-_museosansrounded-500-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/museo/exljbris_-_museosansrounded-500-webfont.woff2') format('woff2'), url('../assets/fonts/museo/exljbris_-_museosansrounded-500-webfont.woff') format('woff'), url('../assets/fonts/museo/exljbris_-_museosansrounded-500-webfont.svg') format('svg');
}

@font-face {
  font-family: 'Museo Sans Rounded';
  font-weight: 300;
  src: url('../assets/fonts/museo/2E49F4_0_0.eot');
  src: url('../assets/fonts/museo/2E49F4_0_0.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/museo/2E49F4_0_0.woff2') format('woff2'), url('../assets/fonts/museo/2E49F4_0_0.woff') format('woff'), url('../assets/fonts/museo/2E49F4_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Museo Sans Rounded';
  font-weight: 100;
  src: url('../assets/fonts/museo/2E49F4_1_0.eot');
  src: url('../assets/fonts/museo/2E49F4_1_0.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/museo/2E49F4_1_0.woff2') format('woff2'), url('../assets/fonts/museo/2E49F4_1_0.woff') format('woff'), url('../assets/fonts/museo/2E49F4_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Museo Sans Rounded';
  font-weight: 700;
  src: url('../assets/fonts/museo/2E49F4_2_0.eot');
  src: url('../assets/fonts/museo/2E49F4_2_0.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/museo/2E49F4_2_0.woff2') format('woff2'), url('../assets/fonts/museo/2E49F4_2_0.woff') format('woff'), url('/museo/2E49F4_2_0.ttf') format('truetype');
}