@import "~styles/config";

.vooRadio,
.vooCheckbox {
  position: absolute;
  opacity: 0;
  box-sizing: border-box;

  +label {
    display: flex;
    padding: 10px 0;
    transform: none;
    transform-origin: left center;
    transition: all .3s ease-in;
    cursor: pointer;
    box-sizing: border-box;

    &:before {
      content: '';
      box-sizing: border-box;
      display: block;
      height: 22px;
      width: 22px;
      border: 2px solid currentColor;
      border-radius: 4px;
      transition: border .15s ease-in;
      margin: -2px 10px 0 0;
    }
  }

  &:checked {

    +label {
      &:before {
        border-width: 8px;
      }
    }
  }

  &:disabled {
    +label {
      color: $color-disabled;
      cursor: auto;

      &:before {
        border-color: $color-disabled;
      }
    }
  }

  @media screen and (min-width: $desk-width) {
    +label {
      padding: 5px 0;
    }
  }
}

.vooRadio {
  +label {
    &:before {
      border-radius: 50%;
    }
  }
}