@import "~styles/config";

.modal {
    .content {
        .buttonsContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        > div {
            margin-top: 20px;
        }
    }
}
legend {
  margin: 20px 0;
  max-width: 100%;
}
